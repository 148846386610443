import React, {FC, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/react-hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faDownload} from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import Section from '../../components/section';
import Button from '../../components/button';
import {Translate} from '../../components/translations';
import SimpleLayout from '../../components/layout_simple';
import {UserVerifyComponentProps, UserVerifyMutationData} from '../../types';
import {USER_VERIFY, MAGNET_VERIFY} from '../../graphql/mutations';
import {setUserToken, setUserHash} from '../../helpers';
import other from '../../images/lead-magnet/automation-playbook-3d.png';
import eCommerce from '../../images/lead-magnet/ecommerce-playbook-3d.png';
import agency from '../../images/lead-magnet/3-Agency-cover.png';
import {verified} from '../../images/svgIcons';

import otherpdf from '../../../static/monitoring-strategy-playbook-all-industries.pdf';
import eCommercepdf from '../../../static/ecommerce-strategy-playbook.pdf';
import agencypdf from '../../../static/hexowatch-agency-usecases.pdf';

import '../styles.scss';

const magnetFields = [
  {
    name: 'eCommerce',
    img: eCommerce,
    pdf: eCommercepdf,
    pdfName: 'Hexowatch-ecommerce-strategy-playbook.pdf',
  },
  {
    name: 'Agency',
    img: agency,
    pdf: agencypdf,
    pdfName: 'Hexowatch-hexowatch-agency-usecases.pdf',
  },
  {
    name: 'Other',
    img: other,
    pdf: otherpdf,
    pdfName: 'Hexowatch-monitoring-strategy-playbook-all-industries.pdf',
  },
];

const VerifyEmail: FC<UserVerifyComponentProps> = ({token}) => {
  const [verifyMutation, {data}] = useMutation<UserVerifyMutationData>(USER_VERIFY);
  const [verifyMagnetMutation, {data: magnetData}] = useMutation(MAGNET_VERIFY);
  const [errorMessage, setError] = useState('');
  const [magnetField, setMagnetField] = useState('');

  useEffect(() => {
    setMagnetField('');
    setError('');
    if (token && token[0] === 'm' && token[1] === '-') {
      verifyMagnetMutation({variables: {verifyCode: token.slice(2)}});
    } else if (token && token !== 'index.html') {
      verifyMutation({variables: {verifyCode: token}});
    }
  }, []);

  useEffect(() => {
    setMagnetField('');
    setError('');
    if (data && data.UserOps && data.UserOps.verify) {
      const {error, message, token, hash} = data.UserOps.verify;
      if (error) {
        setError(message);
      } else {
        setUserToken(token);
        setUserHash(hash);
        window.location.href = window.location.host.includes('37.186.119.181')
          ? 'http://37.186.119.181:3276/hexowatch/add-url'
          : 'https://dash.hexowatch.com/hexowatch/add-url';
      }
    } else if (magnetData && magnetData.WatchSubscribeOps && magnetData.WatchSubscribeOps.verify) {
      const {error, message, comment} = magnetData.WatchSubscribeOps.verify;
      if (error) {
        setError(message);
      } else {
        setMagnetField(comment);
      }
    }
  }, [data, magnetData]);

  const selectedMagnetField = magnetFields.find(item => item.name === magnetField);

  const handleDownloadPdf = () => {
    selectedMagnetField && FileSaver.saveAs(selectedMagnetField.pdf, selectedMagnetField.pdfName);
  };

  //@ts-ignore
  const searchQuery =
    global &&
    global.window &&
    global.window.location &&
    global.window.location.search &&
    global.window.location.search.split('&');
  let success = '';
  let error = '';
  let user_token = '';
  let user_hash = '';
  searchQuery &&
    searchQuery.find((item: any) => {
      if (item.indexOf('token') > -1) {
        user_token = item.slice(item.indexOf('=') + 1, item.length);
        user_token && setUserToken(user_token);
      }
      if (item.indexOf('hash') > -1) {
        user_hash = item.slice(item.indexOf('=') + 1, item.length);
        user_hash && setUserHash(user_hash);
      }
      if (item.indexOf('success') > -1) {
        success = item.slice(item.indexOf('=') + 1, item.length);
      }
      if (item.indexOf('error') > -1) {
        error = item.slice(item.indexOf('=') + 1, item.length);
      }
    });

  useEffect(() => {
    success &&
      setTimeout(() => {
        navigate('/pricing/');
      }, 5000);
  }, []);

  const onClick = () => {
    window.location.replace('https://dash.hexowatch.com/hexowatch');
  };

  return (
    <div className="content-wrapper">
      <SimpleLayout isClickable>
        <Section>
          <div className="col-lg-12 text-center">
            {!token || token === 'index.html' ? (
              error ? (
                <div className="alert alert-danger">
                  <Translate name={'Invalid or expired verification link'} />
                </div>
              ) : (
                success && (
                  <>
                    <div>{verified}</div>
                    <p className="my-3" style={{color: '#00042a', fontWeight: 600, fontSize: '24px'}}>
                      Successfully verified
                    </p>
                    <p className="mb-3 f_size_18 f_400 t_color4">
                      You will be automatically redirected to Pricing page in 5 seconds
                    </p>
                    <Button onClick={onClick}>Go to dashboard</Button>
                  </>
                )
              )
            ) : errorMessage ? (
              <div className="alert alert-danger">
                <Translate name={errorMessage} />
              </div>
            ) : (
              !magnetField && <FontAwesomeIcon icon={faSpinner} spin />
            )}
            {magnetField && selectedMagnetField && (
              <div>
                <div className="mt-4 download-link">
                  <span onClick={() => handleDownloadPdf()}>
                    <FontAwesomeIcon icon={faDownload} className="mr-2 f_size_12" />
                    Download Now
                  </span>
                </div>
                <img
                  onClick={() => handleDownloadPdf()}
                  style={{width: '400px', height: 'auto', cursor: 'pointer'}}
                  src={selectedMagnetField.img}
                  alt={`${selectedMagnetField.name} use cases`}
                />
              </div>
            )}
          </div>
        </Section>
      </SimpleLayout>
    </div>
  );
};

export default VerifyEmail;
